import request from '@/utils/request';

export function listAll() {
  return request({
    url: '/v1/helpCenter/listAll',
    method: 'get',
  });
}
export function resolve(id) {
  return request({
    url: `/v1/helpCenter/resolve/${id}`,
    method: 'post',
  });
}

export function unresolve(id) {
  return request({
    url: `/v1/helpCenter/unresolve/${id}`,
    method: 'post',
  });
}
