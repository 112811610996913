<template>
  <ticket-list></ticket-list>
</template>
<script>
import TicketList from './TicketList.vue';

export default {
  name: 'activeTickets',
  components: { TicketList },
};
</script>
